$(document).ready(function() {

    console.log('document ready');
    // >>> DATEPICKR <<<
    const bookingDates    = '.booking-dates';
    const bookingStart    = '.booking-datestart';
    const bookingEnd      = '.booking-dateend';
    const $bookingWrap    = $('#quote_form').parent();
    // >>>
    const minDateNbr    = $bookingWrap.attr('data-date-min') != null ? parseInt($bookingWrap.attr('data-date-min')) : 0;
    const minDateInitNbr = $bookingWrap.attr('data-date-min-init') != null ? parseInt($bookingWrap.attr('data-date-min-init')) : 0;
    const minDaysNbr    = $bookingWrap.attr('data-day-min') != null ? parseInt($bookingWrap.attr('data-day-min')) - 1 : 0;
    const initDaysNbr   = $bookingWrap.attr('data-day-init') != null ? parseInt($bookingWrap.attr('data-day-init')) - 1 : minDaysNbr;
    // >>>
    const minDateStart  = moment().add(minDateInitNbr, 'days'); 
    const minDateEnd    = moment(minDateStart).add(minDaysNbr, 'days'); 
    const minDateEndSug = moment(minDateStart).add(initDaysNbr, 'days'); 
    // >>>
    const todayMidnight = moment().set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0});

    console.log('end variable declarations');


    // RANGE BOOKING DATES CALENDAR
    if ($(bookingDates).length) {
        console.log('init booking dates');
        let bookingDatesPickr = flatpickr(bookingDates, {
            mode: 'range',
            locale: 'fr',
            dateFormat: 'd/m/Y',
            showMonths: 2,
            minDate: todayMidnight._d,

            // disable: [
            //     function(date) {
            //         console.log(date, date.getMonth(), date.getDate());
            //         return (
            //             (date.getMonth() == 11 && date.getDate() == 25) 
            //             || (date.getMonth() == 0 && date.getDate() == 1)
            //         );
            //     }
            // ],

            onChange: function(selectedDates, dateStr, instance) {
                console.log('booking change');
                // Check if we have date start and end selected 
                if (selectedDates.length == 2) {
                    const dateStart = moment(selectedDates[0]);
                    const dateEnd = moment(selectedDates[1]);
                    const daysTotal = moment.duration(dateEnd.diff(dateStart)).asDays();

                    // Check if the user select a date start that is under the suggested min date, display an advertising popup
                    if (dateStart.isBefore(moment().add(minDateNbr - 1, 'days'))) {
                        $('#booking_min_datestart').modal('show');

                    // Check if the total days is not under the min days
                    } else if (daysTotal < minDaysNbr) {
                        // Show an advertising popup
                        $('#booking_min_days').modal('show');
                        // Change the date end to fit the required min days
                        instance.setDate([dateStart._d, moment(dateStart).add(minDaysNbr, 'days')._d]);
                    }

                    // Check if the min date suggested has been respected
                    // checkMinDateSuggested(selectedDates[0], minDateNbr);
                }
            }
        });
        // Set default dates if there is not already a value inside the input
        if ($(bookingDates).val() == "") {
            console.log('booking date val empty');
            bookingDatesPickr.setDate([minDateStart._d, minDateEndSug._d]);
            bookingDatesPickr.redraw();
        }
    }


    // SINGLE BOOKING START CALENDAR
    $(bookingStart).on('change', function() {
        console.log('booking start change');
        const selectedDate = $(this).val();
        const bookingMinEndDate = moment(selectedDate).add(minDaysNbr + 1, 'days');
        const bookingInitEndDate = moment(selectedDate).add(initDaysNbr, 'days');
        $(bookingEnd).attr('min', bookingMinEndDate.format('YYYY-MM-DD'));
        $(bookingEnd).attr('value', bookingInitEndDate.format('YYYY-MM-DD'));

        // Check if the user select a date start that is under the suggested min date, display an advertising popup
        if (moment(selectedDate).isBefore(moment().add(minDateNbr - 1, 'days'))) {
            $('#booking_min_datestart').modal('show');
        }
    });


    // SINGLE BOOKING START CALENDAR
    // if ($(bookingStart).length) {
    //     let bookingStartPickr = flatpickr(bookingStart, {
    //         //plugins: [new rangePlugin({ input: bookingEnd})],
    //         locale: 'fr',
    //         dateFormat: 'd/m/Y',
    //         minDate: todayMidnight._d,
    //         defaultDate: $(bookingStart).val() == "" ? minDateStart._d : moment($(bookingStart).val(), 'DD/MM/YYYY')._d,

    //         onChange: function(selectedDates, dateStr, instance) {
    //             let bookingEndPickr = $(bookingEnd)[0]._flatpickr;

    //             // Change the min date of the booking pickr end
    //             let bookingMinEndDate   = moment(selectedDates[0]).add(minDaysNbr, 'days');
    //             let bookingInitEndDate  = moment(selectedDates[0]).add(initDaysNbr, 'days');
    //             console.log(bookingInitEndDate._d);
    //             //$(bookingEnd).attr('min', bookingMinEndDate.format('YYYY-MM-DD'));
    //             bookingEndPickr.setDate(bookingInitEndDate._d);
    //             bookingEndPickr.set('minDate', bookingMinEndDate._d);
    //             bookingEndPickr.redraw();
    //             bookingEndPickr.open();

    //             checkMinDateSuggested(selectedDates[0], minDateNbr);
    //         },

    //     });
    // }


    // // SINGLE BOOKING END CALENDAR
    // if ($(bookingEnd).length) {
    //     const bookingStartMoment    = moment($(bookingStart).val(), 'DD/MM/YYYY');
        
    //     let bookingEndPickr = flatpickr(bookingEnd, {
    //         locale: 'fr',
    //         dateFormat: 'd/m/Y',
    //         minDate: minDateEnd._d,
    //         defaultDate: $(bookingEnd).val() == "" ? minDateEndSug._d : moment($(bookingEnd).val(), 'DD/MM/YYYY')._d,

    //         onOpen: function(selectedDates, dateStr, instance) {
    //             /*const bookingStartDate  = moment($(bookingStart)[0]._flatpickr.selectedDates[0]);
    //             const selectedStartDate = moment(selectedDates[0]); 
    //             // Apply the same start date of the booking end if this one is not the same than the booking start date
    //             if (!bookingStartDate.isSame(selectedStartDate)) {
    //                 // Check if there is already an end date, then apply the end date again
    //                 if (selectedDates[1] != undefined) {
    //                     instance.setDate([bookingStartDate._d, selectedDates[1]]);
    //                 } else {
    //                     instance.setDate([bookingStartDate._d]);
    //                 }
    //                 $(instance.element).val(moment(selectedDates[1]).format('DD/MM/YYYY'));
    //             }
    //             $('.flatpickr-calendar.rangeMode').find('.flatpickr-notif').removeClass('show');*/
    //         },

    //         onChange: function(selectedDates, dateStr, instance) {

    //             const bookingStartDate      = $(bookingStart)[0]._flatpickr.selectedDates[0];
    //             const selectedEnd           = selectedDates[0];
    //             const datesDifference       = moment(selectedEnd).diff(moment(bookingStartDate), 'days');

    //             // Check if the date interval is not less than the minDays
    //             if (datesDifference < minDaysNbr) {
    //                 $('#booking_date_infos').modal('show');
    //             } 

    //             // Check to display the notif element if the date diff is not respected
    //             /*const selectedEndReal       = moment(dateStr.split(' ')[2]); // Get the real selected end to preserve a glitch above that can keep the calendar open to modify the end date
    //             const datesDifferenceReal   = selectedEndReal.diff(bookingStartDate, 'days');
    //             if (datesDifferenceReal < minDays) {
    //                 // Display the notification for the user
    //                 $('.flatpickr-calendar.rangeMode').find('.flatpickr-notif').addClass('show');
    //                 $('.flatpickr-calendar.rangeMode').find('.flatpickr-notif').text('Réservation de ' + minDays + ' jours minumum');
    //             } else {
    //                 $('.flatpickr-calendar.rangeMode').find('.flatpickr-notif').removeClass('show');
    //             }*/
                
    //         },
    //     });
    // }
});